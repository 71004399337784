import React from "react";
import Divider from "../Divider";

const PartnersType = (props) => {
  const types = props.content;
  return (
    <>
      <div className="outerContainer partners-type-outer-container">
        <div className="innerContainer partners-type-inner-container">
          <h2 className="partners-type-header-title sectionTitle">
            Types of Partnerships
          </h2>
          <Divider />
          <div className="partners-type-card-container">
            {types.map((type, index) => (
              <div className="partners-type-card" key={index}>
                <div className="partners-type-card-logo">
                  {" "}
                  <img
                    src={type.logo}
                    alt="partner logo"
                    width={40}
                    loading="lazy"
                  />{" "}
                </div>
                <h4 className="partners-type-card-title">{type.title}</h4>
                <div className="partners-type-card-description">
                  {type.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnersType;
