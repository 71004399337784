import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SignUpModal from "../modal/SignUpModal";
import { Button } from "@material-tailwind/react";
import { freeTrailClickFunction } from "../../app-insights/freeTrailClickFunction";
import { motion, useScroll, useTransform } from "framer-motion";
import PromoVideo from "../promo-video/PromoVideo";
import { homeContent } from "../home/HomeContent";
const HomeBanner = (props) => {
  const [isOpen, setIsOpen] = useState(false); // Define isOpen and setIsOpen
  const bannerContent = props.content;

  const recognitionLogos = homeContent.recognizationLogos;
  useEffect(() => {
    AOS.init({
      duration: 500,
      once: false,
    });
  }, []);
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  return (
    <>
      <motion.div className="home-banner-container">
        <div className="home-banner-content-container">
          <motion.div
            className="home-banner-content"
            style={{ scale }}
            ref={targetRef}
          >
            <div style={{ overflow: "hidden" }}>
              <motion.h1
                initial={{
                  rotate: -20,
                  y: 400,
                  x: 20,
                }}
                animate={{ rotate: 0, y: 0, x: 0 }}
                transition={{ ease: "easeOut", duration: 0.8, delay: 0.3 }}
                className="home-banner-content-container-title"
              >
                Empowering businesses with the{" "}
                <span>
                  right AI,
                  {Array.from({ length: 5 }).map((_, starIndex) => (
                    <Star
                      key={starIndex}
                      color={colors[Math.floor(Math.random() * colors.length)]}
                      scale={Math.random() * 0.3 + 0.2}
                      rotate={Math.random() * 360}
                      left={`${Math.random() * 150 - 25}%`}
                      top={`${Math.random() * 150 - 25}%`}
                    />
                  ))}
                </span>{" "}
                the right way
              </motion.h1>
            </div>
            <div
              style={{ overflow: "hidden" }}
              className="home-banner-content-container-description"
            >
              <motion.p
                initial={{
                  rotate: -20,
                  y: 400,
                  x: 20,
                }}
                animate={{ rotate: 0, y: 0, x: 0 }}
                transition={{ ease: "easeOut", duration: 0.8, delay: 0.5 }}
              >
                {bannerContent.description}
              </motion.p>
            </div>
            <div className="home-banner-content-container-button">
              <motion.div
                initial={{
                  y: 400,
                }}
                animate={{ y: 0 }}
                transition={{ ease: "easeOut", duration: 0.8, delay: 0.7 }}
                className="secondary-button  flex flex-col items-center"
              >
                <Button
                  className="button bannerPrimaryButton"
                  onClick={() => {
                    setIsOpen(true);
                    freeTrailClickFunction();
                  }}
                >
                  Start Your Free Trial
                </Button>
                <p className="credit-card-text">*No credit card required</p>
              </motion.div>
            </div>
          </motion.div>
          <PromoVideo videoURL="https://inextlabswebassets.blob.core.windows.net/partners-demo-assets-video/AI%20is%20everywhere,%20Is%20it%20for%20everyone.mp4" />
          <div className="home-banner-recognization-section">
            <h2 className="sectionTitle">
              Recognised by the world’s top organizations
            </h2>
            <div className="home-banner-recognization-logos">
              {recognitionLogos.map((logo, index) => {
                return (
                  <img
                    key={index}
                    src={logo.imageURL}
                    alt={logo.alt}
                    data-aos="fade-up"
                    loading="lazy"
                  />
                );
              })}
            </div>
          </div>
        </div>
      </motion.div>
      <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default HomeBanner;

// Word wrapper
const Wrapper = (props) => {
  // We'll do this to prevent wrapping of words using CSS
  return <span className="word-wrapper">{props.children}</span>;
};

// Map API "type" vaules to JSX tag names
const tagMap = {
  paragraph: "p",
  heading1: "h1",
  heading2: "h2",
};

// AnimatedCharacters
// Handles the deconstruction of each word and character to setup for the
// individual character animations
const AnimatedCharacters = (props) => {
  // Framer Motion variant object, for controlling animation
  const item = {
    hidden: {
      y: "200%",
      color: "#0055FF",
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 },
    },
    visible: {
      y: 0,
      color: "#FF0088",
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 },
    },
  };

  //  Split each word of props.text into an array
  const splitWords = props.text.split(" ");

  // Create storage array
  const words = [];

  // Push each word into words array
  for (const [, item] of splitWords.entries()) {
    words.push(item.split(""));
  }

  // Add a space ("\u00A0") to the end of each word
  words.map((word) => {
    return word.push("\u00A0");
  });

  // Get the tag name from tagMap
  const Tag = tagMap[props.type];

  return (
    <Tag>
      {words.map((word, index) => {
        return (
          // Wrap each word in the Wrapper component
          <Wrapper key={index}>
            {words[index].flat().map((element, index) => {
              return (
                <span
                  style={{
                    overflow: "hidden",
                    display: "inline-block",
                  }}
                  key={index}
                >
                  <motion.span
                    style={{ display: "inline-block" }}
                    variants={item}
                  >
                    {element}
                  </motion.span>
                </span>
              );
            })}
          </Wrapper>
        );
      })}
      {/* {} */}
    </Tag>
  );
};

const colors = ["#9E7AFF", "#FE8BBB"];

const Star = ({ color, scale, rotate, left, top }) => (
  <motion.svg
    className="absolute pointer-events-none"
    width="40"
    height="40"
    viewBox="0 0 21 21"
    initial={{ opacity: 0 }}
    animate={{ opacity: [0, 1, 0] }}
    transition={{
      duration: Math.random() * 1.5 + 1,
      repeat: Infinity,
      repeatType: "loop",
      ease: "easeInOut",
    }}
    style={{ left, top, transform: `scale(${scale}) rotate(${rotate}deg)` }}
  >
    <path
      d="M9.82531 0.843845C10.0553 0.215178 10.9446 0.215178 11.1746 0.843845L11.8618 2.72026C12.4006 4.19229 12.3916 6.39157 13.5 7.5C14.6084 8.60843 16.8077 8.59935 18.2797 9.13822L20.1561 9.82534C20.7858 10.0553 20.7858 10.9447 20.1561 11.1747L18.2797 11.8618C16.8077 12.4007 14.6084 12.3916 13.5 13.5C12.3916 14.6084 12.4006 16.8077 11.8618 18.2798L11.1746 20.1562C10.9446 20.7858 10.0553 20.7858 9.82531 20.1562L9.13819 18.2798C8.59932 16.8077 8.60843 14.6084 7.5 13.5C6.39157 12.3916 4.19225 12.4007 2.72023 11.8618L0.843814 11.1747C0.215148 10.9447 0.215148 10.0553 0.843814 9.82534L2.72023 9.13822C4.19225 8.59935 6.39157 8.60843 7.5 7.5C8.60843 6.39157 8.59932 4.19229 9.13819 2.72026L9.82531 0.843845Z"
      fill={color}
    />
  </motion.svg>
);
