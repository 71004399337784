import { Button } from "@material-tailwind/react";
import { motion, useScroll, useTransform } from "framer-motion";
import React, { useRef } from "react";
import { Link } from "react-router-dom";

const Redirect = (props) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);

  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <motion.div
            className="redirect-page-container"
            style={{ scale }}
            ref={ref}
          >
            <div className="redirect-page-image">
              <img
                src={props.content.imageURL}
                alt="redirect-page-image"
                loading="lazy"
              />
            </div>
            <div className="redirect-page-content">
              <h2 className="sectionTitle left">{props.content.title}</h2>
              <p className="para">{props.content.tagline}</p>
              <div className="redirect-page-button">
                <Link to={props.content.redirectURL}>
                  <Button className="button">Explore more</Button>
                </Link>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Redirect;
