export const infoTableContent = {
  infoTable: [
    {
      id: "1",
      title: "Global Architecture & Design Consultancy ",
      description:
        "A leading architecture and industrial design firm has enhanced its HR capabilities with our innovative HR Assistant. This intelligent tool addresses the needs of their 900-strong, globally dispersed workforce by considering factors like location, seniority, and gender, streamlining HR operations and improving policy compliance, resulting in a better work environment. ",
      imageURL: "/images/generative-ai/crop-people-working-with-schemes.webp",
    },
    {
      id: "2",
      title: "International Financial Institution",
      description:
        "As an ongoing initiative, iNextLabs collaborates with a financial giant to continuously enhance customer service through their intelligent voice-assistant system. This system is specifically tailored to streamline credit card inquiries, providing efficient handling of tasks like fee waivers and charge adjustments. By ensuring responsive support, the ongoing implementation reinforces the financial giant's commitment to delivering exceptional service to its vast customer base, sustaining a culture of excellence in customer care. ",
      imageURL: "/images/generative-ai/pexels-pratikxox-3222683.webp",
    },
    {
      id: "3",
      title: "Judicial Information System",
      description:
        "A leading digital library has transformed legal research across the judiciary, from top courts to local tribunals. With a recent upgrade integrating NLP technology, attorneys and public prosecutors can conduct research more efficiently, reducing preparation time and improving case accuracy. ",
      imageURL:
        "/images/generative-ai/lawyer-woman-s-hands-with-judicial-gavel-during-online-consultation-with-client-lawyer-s-office.webp",
    },
  ],
};
