import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import { resourcesContent } from "./resourcesContent";
import { Link } from "react-router-dom";

const ResourceDetailsItems = (props) => {
  const [copied, setCopied] = useState(false);
  const currentUrl = window.location.href;
  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 30000); // 60 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or copied state change
    }
  }, [copied]);

  const relatedResources = resourcesContent.resourceItems.filter(
    (resource) =>
      resource.type === props.content.type && resource.url !== props.content.url
  );
  const relatedResourcesContent = relatedResources.slice(0, 3);
  return (
    <>
      <div className="outerContainer resource-details-items-outer-container">
        <div className="innerContainer">
          <div className="resource-details-items-container">
            <div className="resource-details-items-content-container">
              <div className="resource-details-items-content-image">
                <img
                  src={props.content.imageURL}
                  alt="resources image"
                  className={
                    props.content.type === "Webinar" &&
                    "resource-details-items-content-image-webinar"
                  }
                />
              </div>
              <div className="resource-details-items-content-data-container">
                <div className="resource-details-items-content">
                  <div
                    dangerouslySetInnerHTML={{ __html: props.content.content }}
                    className="resource-details-items-content-data"
                  ></div>
                  {props.content.type === "Webinar" &&
                    props.content.registrationURL && (
                      <div className="resource-details-items-content-reg-link">
                        <a
                          href={props.content.registrationURL}
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "#007bff" }}
                        >
                          <button className="button">Register Now</button>
                        </a>
                      </div>
                    )}
                  <Divider />

                  <div className="resource-details-items-content-social-share">
                    Share this article:
                    <div className="resource-details-items-content-social-share-btn">
                      <FacebookShareButton
                        url={currentUrl}
                        quote={props.content.title}
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={currentUrl}
                        title={props.content.title}
                      >
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        url={currentUrl}
                        title={props.content.title}
                      >
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                      <WhatsappShareButton
                        url={currentUrl}
                        title={props.content.title}
                        separator=":: "
                      >
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                    </div>
                  </div>
                  <div className="copy-link-container">
                    <input value={currentUrl} />
                    <CopyToClipboard
                      text={currentUrl}
                      onCopy={() => setCopied(true)}
                    >
                      <button className="button">
                        {!copied ? "Copy" : "Copied"}
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="resource-details-items-related-content">
                  <div className="resource-details-items-more-title sectionTitle left">
                    Related Resources
                  </div>
                  <div className="resource-details-items-more-list">
                    {relatedResourcesContent.map((resource, index) => (
                      <div
                        key={index}
                        className="resource-details-items-more-list-item"
                      >
                        <Link
                          to={resource.url ? `/resources/${resource.url}` : `/`}
                        >
                          <img
                            src={resource.imageURL}
                            alt="resources image"
                            className="resource-details-items-more-list-item-image"
                            loading="lazy"
                          />
                          <div className="resource-details-items-more-list-item-title">
                            {resource.title}
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourceDetailsItems;
