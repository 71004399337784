import React from "react";
import moment from "moment/moment";
import { FaBlog } from "react-icons/fa";
import { RiBook2Line } from "react-icons/ri";
import { BiTimeFive } from "react-icons/bi";
import { Link } from "react-router-dom";

const ResourcesItem = (props) => {
  return (
    <>
      <Link
        className="resourceLink"
        to={props.content.url ? `/resources/${props.content.url}` : `/`}
      >
        <div className="resources-item-container">
          <div className="resource-card resource-card-resource">
            <div className="resource-card-image">
              <img
                className="img"
                src={props.content.imageURL}
                alt="resource Image"
                loading="lazy"
              />
              {props.content.industry && (
                <div className="resource-industry">
                  {props.content.industry}
                </div>
              )}
            </div>
            <div className="resource-content">
              <div className="resource-content-header">
                <h5 className="resource-category resource-text-success">
                  {props.content.type === "Blog" ? (
                    <FaBlog size={16} />
                  ) : (
                    <RiBook2Line size={16} />
                  )}
                  {props.content.type}
                </h5>
                {props.content.country && (
                  <div className="resource-content-country">
                    <p className="para">{props.content.country}</p>
                  </div>
                )}
              </div>
              <div className="resource-card-caption-container">
                <h2 className="resource-card-caption">{props.content.title}</h2>
              </div>
              <div className="resource-card-footer">
                <div className="resource-footer-button">
                  <Link
                    to={
                      props.content.url
                        ? `/resources/${props.content.url}`
                        : `/`
                    }
                  >
                    Read more...
                  </Link>
                </div>
                <div className="resource-footer-time">
                  <BiTimeFive size={18} />{" "}
                  <span>
                    {moment
                      .unix(props.content.publishedDate)
                      .format("MMM Do YYYY")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ResourcesItem;
