import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HeaderMenu from "./HeaderMenu";
import HeaderMenuBurger from "./HeaderMenuBurger";
import { HashLink } from "react-router-hash-link";
import { CiBullhorn } from "react-icons/ci";
import SignUpModal from "../modal/SignUpModal";
import { Button } from "@material-tailwind/react";
import { freeTrailClickFunction } from "../../app-insights/freeTrailClickFunction";

const Header = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // Define isOpen and setIsOpen

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    setIsVisible(prevScrollPos > currentScrollPos);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <>
      <div className={`header ${isVisible ? "" : "header-hidden"}`}>
        <div className={`header-top-container ${isVisible ? "" : "hidden"}`}>
          <div className="header-top-right">
            <div className="header-top-announcement">
              <div className="header-top-announcement-text">
                <Link to="/news/inextlabs-achieves-malaysia-digital-status%2c-leading-the-ai-revolution-for-business-excellence">
                  <CiBullhorn size={17} style={{ fontWeight: "bold" }} />
                  We have achieved Malaysia Digital Status
                </Link>
              </div>
            </div>
            <div className="header-top-info">
              <div>En</div>
              <div className="header-top-location-dropdown">
                <div
                  className="header-top-location-dropdown-toggle"
                  onClick={toggleDropdown}
                >
                  Singapore
                </div>
                {isDropdownVisible && (
                  <div className="header-top-location-dropdown-menu">
                    <div>Malaysia</div>
                    <div>USA</div>
                    <div>India</div>
                  </div>
                )}
              </div>
              <Link to="https://demo.inextlabs.ai/" target={"_blank"}>
                Partner Login
              </Link>
            </div>
          </div>
        </div>
        <div className={`desktopMenu ${isVisible ? "" : "desktopMenu-hidden"}`}>
          <div className="headerContainer">
            <Link to="/">
              <img
                src="/images/general/inextlabs-logo.png"
                alt="iNextLabs Logo"
                loading="lazy"
                className="headerLogo"
              />
            </Link>
            <HeaderMenu />
            <div className="header-buttons">
              <HashLink smooth to="/contact/#contactform">
                <Button className="button bannerSecondaryButton">
                  Contact Us
                </Button>
              </HashLink>

              <Button
                className="button  header-button-two"
                onClick={() => {
                  setIsOpen(true);
                  freeTrailClickFunction();
                }}
              >
                Start Your Free Trial
              </Button>
            </div>
          </div>
        </div>

        <div className="burgerMenu">
          <div className="headerContainer">
            <div>
              <Link to="/">
                <img
                  className="headerLogo"
                  src="/images/general/inextlabs-logo.png"
                  alt="inextlabs burgermenu logo"
                  loading="lazy"
                />
              </Link>
            </div>

            <div className="burgerMenu">
              <HeaderMenuBurger />
            </div>
          </div>
        </div>
        <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </>
  );
};

export default Header;
