import {
  MdOutlineFlight,
  MdHotel,
  MdBusiness,
  MdDirectionsSubway,
  MdLocalHospital,
  MdEvent,
  MdStore,
  MdSchool,
} from "react-icons/md"; // Import necessary icons
export const avatarAssistantsContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    secondaryButton: {
      text: "Download Brochure",
      url: "https://inextlabswebassets.blob.core.windows.net/partners-demo-assets-brochures/AI%20Avatar%20Assistant%20Brochure.pdf",
    },
    description:
      "Transform customer interactions with our AI-powered digital humans. Powered by Generative AI, our Avatar Assistants offer human-like, multilingual communication for personalized, seamless experiences. Perfect for various industries, these advanced avatars enhance engagement and streamline processes, elevating customer interactions to the next level.",
    imageURL: "/images/avatar-assistant/banner.webp",
    sectionTile: "",
    title: "Give your business a face with AI Avatar Assistant",
  },
  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "/images/avatar-assistant/your-brand-your-avatar.png",
      pointer: "/images/icons/pointer.svg",
      title: "Your brand, your Avatar!",
      mainDescription:
        "AI Avatar Assistant can be trained to reflect your brand’s unique voice and personality, allowing for highly personalized interactions. By customizing the avatar’s responses and behavior based on user data, businesses can create tailored experiences that resonate with customers, fostering deeper connections and enhancing loyalty.",
    },
    {
      id: "2",
      model: "model1",
      imageURL: "/images/avatar-assistant/launch-with-ease.png",
      pointer: "/images/icons/pointer.svg",
      title: "Launch with ease",
      mainDescription:
        "Seamlessly integrate the AI Avatar Assistant into existing platforms and workflows, enabling a quick and smooth launch. This versatility allows businesses to leverage the avatar’s capabilities across various channels—whether on websites, mobile apps, or social media—ensuring a consistent and engaging customer experience.",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "/images/avatar-assistant/engage-like-never-before.png",
      pointer: "/images/icons/pointer.svg",
      title: "Engage like never before",
      mainDescription:
        "AI Avatar Assistant creates a more interactive and immersive experience, engaging users as if they were communicating with a real person. This heightened engagement is particularly impactful in marketing and entertainment contexts, leading to increased customer interest and retention.",
    },
    {
      id: "4",
      model: "model1",
      imageURL: "/images/avatar-assistant/feel-the-connection.png",
      pointer: "/images/icons/pointer.svg",
      title: "Feel the connection",
      mainDescription:
        "Utilizing non-verbal cues such as nodding and smiling, the AI Avatar Assistant enhances interactions by conveying empathy and understanding. This human-like behavior enriches customer support experiences, making users feel valued and heard, which is essential for effective communication and problem resolution.",
    },
  ],
  whyChooseUs: {
    title: "Why choose iNextLabs AI Avatar Assistant? ",
    tagline:
      "Powered by Gen AI, our Avatars bring your digital interactions to life. Experience a unique, dynamic journey as they enhance your digital experiences. ",
    content: [
      {
        id: "1",
        title: "Generative AI-Powered Conversations ",
      },
      {
        id: "2",
        title: "Multilingual Capabilities",
      },
      {
        id: "3",
        title: "Custom Avatars with Neural Voices",
      },
      {
        id: "4",
        title: "Self-Learning AI",
      },
      {
        id: "5",
        title: "Seamless Integration",
      },
      {
        id: "6",
        title: "Privacy and Security",
      },
    ],
    features: [
      {
        className: "features-details-card-add",
        id: "1",
        imageURL: "/images/avatar-assistant/lisa-casual-sitting-thumbnail.png",
      },
      {
        className: "features-details-card-even",
        id: "2",
        imageURL: "/images/avatar-assistant/max-casual-thumbnail.png",
      },
      {
        className: "features-details-card-add",
        id: "3",
        imageURL: "/images/avatar-assistant/harry-casual-thumbnail.png",
      },
      {
        className: "features-details-card-even",
        id: "4",
        imageURL: "/images/avatar-assistant/meg-business-thumbnail.png",
      },
    ],
  },

  valueProposition: {
    title: "Deploy anywhere, engage everywhere ",
    tagline:
      "Effortlessly integrate the AI Avatar Assistant on multiple devices across industries ",
    content: [
      {
        id: "1",
        title: "Airports",
        tagline:
          "Provides real-time flight information and directions, enhancing passenger convenience and reducing stress.",
        icon: <MdOutlineFlight size={20} />, // Icon representing airports
      },
      {
        id: "2",
        title: "Hotels",
        tagline:
          "Assists with check-in, room service requests, and local recommendations, elevating guest satisfaction and streamlining operations.",
        icon: <MdHotel size={20} />, // Icon representing hotels
      },
      {
        id: "3",
        title: "Corporate Offices",
        tagline:
          "Offers onboarding support, meeting scheduling, and employee assistance, improving workplace efficiency and communication.",
        icon: <MdBusiness size={20} />, // Icon representing corporate offices
      },
      {
        id: "4",
        title: "Metro Stations",
        tagline:
          "Provides travel updates, route information, and ticket purchasing assistance, enhancing commuter experience and accessibility.",
        icon: <MdDirectionsSubway size={20} />, // Icon representing metro stations
      },
      {
        id: "5",
        title: "Hospitals",
        tagline:
          "Guides patients through check-in processes and provides health information, improving patient navigation and care experience.",
        icon: <MdLocalHospital size={20} />, // Icon representing hospitals
      },
      {
        id: "6",
        title: "Entertainment Venues",
        tagline:
          "Assists with ticket sales, event information, and audience engagement, enhancing visitor experience and boosting attendance.",
        icon: <MdEvent size={20} />, // Icon representing entertainment venues
      },
      {
        id: "7",
        title: "Retail Stores",
        tagline:
          "Offers personalized shopping assistance and product recommendations, increasing customer engagement and sales.",
        icon: <MdStore size={20} />, // Icon representing retail stores
      },
      {
        id: "8",
        title: "Educational Institutions",
        tagline:
          "Supports students with course information and enrollment processes, enhancing learning experiences and administrative efficiency.",
        icon: <MdSchool size={20} />, // Icon representing educational institutions
      },
    ],
  },

  demoVideo: {
    title: "Experience our Avatar in action",
    description:
      "Discover how AI Avatar Assistant comes to life and explore its limitless possibilities",
    videoURL: "https://www.youtube.com/embed/8XvF9gZJ0PQ",
  },

  FAQ: [
    {
      id: "1",
      question:
        "1. How can a Generative AI Avatar enhance customer loyalty and retention?",
      answer:
        "By providing personalized interactions that understand customer preferences and needs, the AI Avatar can foster stronger relationships. This tailored engagement not only improves satisfaction but also encourages repeat business, making customers feel valued and heard.",
    },
    {
      id: "2",
      question:
        "2. How can I ensure the AI Avatar meets the specific needs of my business?",
      answer:
        "By utilizing customizable features and machine learning capabilities, the AI Avatar can be trained on your business’s unique data, ensuring it understands your products, services, and customer interactions. Regular feedback and adjustments further refine its performance, making it a tailored solution for your operational needs.",
    },
    {
      id: "3",
      question:
        "3. How quickly can I integrate the Generative AI Avatar into my existing systems?",
      answer:
        "Integration is designed to be seamless and can often be accomplished within a matter of weeks. With adaptable APIs, Avatar can connect to your existing platforms, ensuring a smooth transition and immediate enhancement of customer interactions.",
    },
    {
      id: "4",
      question:
        "4. What level of customization is available for the AI Avatar to reflect my brand's identity?",
      answer:
        "The AI Avatar can be extensively customized to align with your brand’s voice, personality, and visual identity. From tailored responses to specific tone adjustments and visual designs, you can create an Avatar that truly represents your business ethos and engages customers authentically.",
    },
  ],
  stats: {
    title: "inFlow Assistant that deliver real business value ",
    description: "Cut wait times, streamline tasks, and enhance response speed",
    content: [
      {
        id: "1",
        title: "routine customer inquiries handled",
        count: 80, // Since this is a percentage, you may not have a fixed count.
      },
      {
        id: "2",
        title: "decrease in IT resolution times",
        count: 50, // Similarly, this represents a percentage.
      },
      {
        id: "3",
        title: "Improved response time",
        count: 45,
      },
      {
        id: "4",
        title: "Reduced wait times. for customers",
        count: 50,
      },
    ],
  },

  meta: {
    title: "AI Avatar Assistant | AI-Powered Digital Humans by iNextLabs",
    description:
      "Transform interactions with iNextLabs' AI Avatar Assistant. Enjoy human-like multilingual communication and boost customer experiences with AI powered digital humans.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
