import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import SignUpModal from "../../modal/SignUpModal";
import { Button } from "@material-tailwind/react";
import { freeTrailClickFunction } from "../../../app-insights/freeTrailClickFunction";

const GenerativeAiBanner = () => {
  const [isOpen, setIsOpen] = useState(false); // Define isOpen and setIsOpen

  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="generative-ai-banner-container">
            <div className="generative-ai-banner-text">
              <div className="generative-ai-banner-title">
                <h1 className="bannerTitle">
                  <span> Generative AI </span> <br /> for your Enterprise
                </h1>
              </div>
              <div className="generative-ai-banner-subtitle">
                <p className="para">
                  iNextLabs pioneers Generative AI solutions, transforming
                  enterprise innovation by empowering leaders to evaluate the
                  potential of ideas for practical implementation. Leveraging
                  advanced algorithms and data analytics, our platforms
                  streamline processes, accelerating time-to-market and driving
                  sustainable success in today's dynamic business landscape.
                </p>
              </div>
              <div className="generative-ai-banner-button">
                <Button
                  className="button bannerPrimaryButton"
                  onClick={() => {
                    setIsOpen(true);
                    freeTrailClickFunction();
                  }}
                >
                  Start Your Free Trial
                </Button>
                <p className="credit-card-text">*No credit card required</p>
              </div>
              <img
                src="/images/ai-verify.png"
                alt="ai-verify"
                className="generative-ai-achievement"
                loading="lazy"
              />
            </div>
            <div className="generative-ai-banner-image">
              <img
                src="/images/generative-ai/gen-ai-banner.webp"
                alt="gen-ai-banner"
                loading="lazy"
              />
            </div>
          </div>
          <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
    </>
  );
};

export default GenerativeAiBanner;
