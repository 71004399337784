import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import SignUp from "../sign-up/SignUp";
import { IoClose } from "react-icons/io5";
const SignUpModal = ({ isOpen, setIsOpen }) => {
  return (
      <AnimatePresence>
        {isOpen && (
          <div className="modal-overlay">
            <div className="signup-modal-container">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={() => setIsOpen(false)}
              >
                <motion.div
                  initial={{ scale: 0, rotate: "12.5deg" }}
                  animate={{ scale: 1, rotate: "0deg" }}
                  exit={{ scale: 0, rotate: "0deg" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <SignUp />
                  <div className="signup-modal-close">
                    <IoClose onClick={() => setIsOpen(false)} size={25}/>
                  </div>
                </motion.div>
              </motion.div>
            </div>
          </div>
        )}
      </AnimatePresence>
  );
};

export default SignUpModal;
