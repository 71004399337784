import React from "react";
import Header from "../header/Header";
import Filler from "../header/Filler";
import Footer from "../footer/Footer";
import InfoTable from "../home-info-table/InfoTable";
import { generativeAiContent } from "./generativeAiContent";
import Pillers from "./Pillers";
import GenerativeAiBanner from "./banner/GenerativeAiBanner";
import Divider from "../Divider";
import DiscoveryWorkshop from "../discovery-workshop/DiscoveryWorkshop";
import Meta from "../Meta";

const GenerativeAi = () => {
  return (
    <>
      <Meta
        title={generativeAiContent.meta.title}
        description={generativeAiContent.meta.description}
        image={generativeAiContent.meta.image}
        locale={generativeAiContent.meta.locale}
        siteName={generativeAiContent.meta.siteName}
        type={generativeAiContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <GenerativeAiBanner />
      <GenerativeAiSolutions content={generativeAiContent.solutions} />
      <Pillers content={generativeAiContent.pillers} />
      <InfoTable />
      {/* <InnerPageValueProps content={generativeAiContent.valueProps} /> */}
      <GenerativeAiInfographic />
      <DiscoveryWorkshop />

      <Footer />
    </>
  );
};

export default GenerativeAi;

const GenerativeAiSolutions = (props) => {
  return (
    <>
      <div className="outerContainer generative-ai-solution-outer-container">
        <div className="innerContainer generative-ai-solution-inner-container">
          <div className="generative-ai-solution-container">
            <div className="generative-ai-solution-title">
              <h2 className="sectionTitle">
                iNextLabs Generative-Al Solutions
              </h2>
              <Divider />
            </div>
            <div className="generative-ai-solution-body-container">
              {props.content.map((solution, index) => (
                <div className="generative-ai-solution-card">
                  <div className="generative-ai-solution-icon">
                    <img
                      src={solution.icon}
                      alt="generative-ai-solution-icon"
                      width={40}
                      loading="lazy"
                    />
                  </div>
                  <div className="generative-ai-solution-card-title">
                    <h3 className="paragraphTitle">{solution.title}</h3>
                  </div>
                  <div className="generative-ai-solution-card-text para">
                    <p>{solution.description1}</p>
                    <p>{solution.description2}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const GenerativeAiInfographic = (props) => {
  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer generative-ai-solution-inner-container">
          <div className="generative-ai-solution-container">
            <div className="generative-ai-solution-title">
              <h2 className="sectionTitle">
                Staying at the top of the learning curve
              </h2>
              <Divider />
            </div>
            <img
              src="/images/generative-ai/generative-ai-info-diagram.webp"
              alt="generative ai info diagram"
              width={"100%"}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </>
  );
};
