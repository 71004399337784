import React, { useState } from "react";
import SignUpModal from "../../modal/SignUpModal";
import { Button } from "@material-tailwind/react";
import { freeTrailClickFunction } from "../../../app-insights/freeTrailClickFunction";

const ProductsBanner = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="outerContainer products-banner-outer-container">
        <div className="products-banner-container">
          <div
            className={
              props.content.bannerType === "inflow"
                ? "products-banner-left-inflow"
                : "products-banner-left"
            }
          >
            {props.content.bannerType === "inflow" ? (
              <div
                className="products-banner-title"
                dangerouslySetInnerHTML={{ __html: props.content.title }}
              ></div>
            ) : (
              <h1 className="products-banner-title bannerTitle">
                {props.content.title}
              </h1>
            )}
            {props.content.bannerType === "inflow" && (
              <img
                src="/images/products/inflow/meta.png"
                alt="inflow"
                className="inflow-meta-image"
                loading="lazy"
              />
            )}
            <p className="para">{props.content.description}</p>
            <div className="products-banner-button">
              <Button
                className="button"
                onClick={() => {
                  setIsOpen(true);
                  freeTrailClickFunction();
                }}
              >
                Start Your Free Trial
              </Button>
              <p className="credit-card-text">*No credit card required</p>
            </div>
          </div>
          <div
            className={
              props.content.bannerType === "inflow"
                ? "products-banner-right-inflow"
                : " products-banner-right"
            }
          >
            <div
              className={
                props.content.bannerType === "inflow"
                  ? "products-banner-right-image-inflow"
                  : " products-banner-right-image"
              }
            >
              <img
                src={props.content.imageURL}
                alt="products-banner"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
      <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default ProductsBanner;
