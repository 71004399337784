import React from "react";
const InnerPageFeaturesItem = (props) => {
  return (
    <>
      <div class="innerpage-features-card">
        <img
          src={props.content.imageURL}
          alt="swimlane image"
          width={100}
          loading="lazy"
        />
        <h4 className="innerpage-features-card-title">{props.content.title}</h4>
        <div className="innerpage-features-card-content">
          <div className="innerpage-features-card-description">
            <li> {props.content.point1}</li>
          </div>
          <div className="innerpage-features-card-description">
            <li> {props.content.point2}</li>
          </div>
          <div className="innerpage-features-card-description">
            <li> {props.content.point3}</li>
          </div>
          <div className="innerpage-features-card-description">
            <li> {props.content.point4}</li>
          </div>
        </div>
      </div>
    </>
  );
};

export default InnerPageFeaturesItem;
