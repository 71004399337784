import React, { useRef } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { motion, useInView, useScroll, useTransform } from "framer-motion";

const IndustryType = (props) => {
  const container = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.2,
        type: "spring",
        ease: "easeInOut",
      },
    },
  };

  const cardItem = {
    hidden: { y: 50 },
    visible: {
      y: 0,
    },
  };
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  return (
    <div>
      <motion.div
        className="outerContainer"
        variants={container}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        ref={ref}
      >
        <motion.div className="innerContainer" style={{ scale }} ref={ref}>
          <div className="industry-type-container">
            <div className="industry-type-header">
              <div>
                <h2 className="industry-type-header-title sectionTitle left">
                  Generate value from AI across business use cases
                </h2>
              </div>
              <Link
                to={"/solution/other-industries"}
                className="industry-type-header-desc"
              >
                <p>Explore all industries</p>
                <FaArrowRightLong />
              </Link>
            </div>
            {props.content.map((item, index) => (
              <motion.div
                variants={cardItem}
                className={`industry-${index} industry-type-content`}
                key={index}
              >
                <div className="industry-type-content-icon">
                  <img
                    src={item.icon}
                    alt="industry-type-content-icon"
                    loading="lazy"
                  />
                </div>
                <div className="industry-type-content-text">
                  <h3 className="paragraphTitle">{item.label}</h3>
                  <p className="para">{item.desc}</p>
                </div>
                <Link to={item.pageURL}>
                  <div className="industry-type-content-nav">
                    <FaArrowRightLong />
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default IndustryType;
