import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "../components/home/Home";
import Bank from "../components/solutions/inextbank/Bank";
import Restaurent from "../components/solutions/inextfnb/Restaurent";
import Retail from "../components/solutions/inextretail/Retail";
import Healthcare from "../components/solutions/inexthealth/Healthcare";
import Blogs from "../components/resources/Blogs";
import CaseStudies from "../components/resources/CaseStudies";
import Partners from "../components/partners/Partners";
import About from "../components/company/about-us/About";
import Career, {
  CareerJobInterestForm,
} from "../components/company/career/Career";
import Contact from "../components/company/contact/Contact";
import NoPageFound from "../components/no-page-found/NoPageFound";
import News from "../components/company/news/News";
import ResourceDetails from "../components/resources/ResourceDetails";
import NewsDetails from "../components/company/news/NewsDetails";
import ScrollToTop from "../components/ScrollToTop";
import PrivacyPolicy from "../components/terms-policy/PrivacyPolicy";
import TermsOfService from "../components/terms-policy/TermsOfService";
import SignUp from "../components/sign-up/SignUp";
import HumanResources from "../components/solutions/inexthr/HumanResources";
import Library from "../components/resources/library/Library";
import GenerativeAI from "../components/generative-ai/GenerativeAi";
import CareerOpenings from "../components/company/career/CareerOpenings";
import Legal from "../components/solutions/legal/Legal";
import OtherIndustries from "../components/solutions/other-industries/OtherIndustries";
import SalesAndMarketing from "../components/solutions/sales-and-marketing/SalesAndMarketing";
import CustomerSupportAutomation from "../components/solutions/customer-support-automation/CustomerSupportAutomation";
import Webinar from "../components/resources/Webinar";
import WebinarDetails from "../components/resources/WebinarDetails";
import InformationTechnology from "../components/solutions/information-technology/InformationTechnology";
import Procurement from "../components/solutions/procurement/Procurement";
import AIAssistants from "../components/products/ai-assitants/AIAssistants";
import Analyzer from "../components/products/ai-analyzer/Analyzer";
import Insights from "../components/products/ai-insights/Insights";
import FoundationModal from "../components/products/foundation-modal/FoundationModal";
import Security from "../components/products/security/Security";
import Omnichannel from "../components/products/omnichannel/Omnichannel";
import Integrations from "../components/products/integrations/Integrations";
import DataGovernance from "../components/products/data-governance/DataGovernance";
import AIAvatarAssist from "../components/ai-avatar-assist/AIAvatarAssist";
import AiAgentAssist from "../pages/ai-agent-assist/AiAgentAssist";

const AppRouter = () => {
  const { hostname } = window.location;

  useEffect(() => {
    const commonHostname = "inextlabs.ai";

    if (
      hostname === "www.inextlabs.ai" ||
      hostname === "www.inextlabs.com" ||
      hostname === "inextlabs.com" ||
      hostname === "inextlabs-website-as.azurewebsites.net"
    ) {
      window.location.href = `https://${commonHostname}${window.location.pathname}`;
    }
  }, []);
  useEffect(() => {
    if (hostname === "https://inextlabs.ai/products/inflow") {
      window.location.href = "https://inextlabs.ai/products/ai-assistants";
    } else if (hostname === "https://inextlabs.ai/products/inflow-enterprise") {
      window.location.href = "https://inextlabs.ai/products/ai-assistants";
    }
  }, [hostname]);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense
        fallback={
          <div>
            <img src="/images/loading.svg" alt="Loading" srcSet="" />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Home />} exact={true} />
          <Route
            path="/generative-ai"
            element={<GenerativeAI />}
            exact={true}
          />
          <Route path="/products/ai-assistants" element={<AIAssistants />} />
          <Route path="/products/ai-analyzer" element={<Analyzer />} />
          <Route path="/products/ai-insights" element={<Insights />} />
          <Route path="/products/ai-agent-assist" element={<AiAgentAssist />} />
          <Route
            path="/products/inflow-enterprise"
            element={<Navigate to="/products/ai-assistants" replace />}
          />
          <Route
            path="/products/inflow"
            element={<Navigate to="/products/ai-assistants" replace />}
          />
          <Route
            path="/products/foundation-model"
            element={<FoundationModal />}
          />
          <Route path="/products/security" element={<Security />} />
          <Route path="/products/omnichannel" element={<Omnichannel />} />
          <Route
            path="/products/data-governance"
            element={<DataGovernance />}
          />
          <Route path="/products/integrations" element={<Integrations />} />
          <Route path="/solution" element={<Restaurent />} exact={true} />
          <Route path="/solution/bank" element={<Bank />} />
          <Route path="/solution/restaurant" element={<Restaurent />} />
          <Route path="/solution/retail" element={<Retail />} />
          <Route path="/solution/healthcare" element={<Healthcare />} />
          <Route path="/solution/legal" element={<Legal />} />
          <Route
            path="/solution/sales-and-marketing"
            element={<SalesAndMarketing />}
          />
          <Route
            path="/solution/customer-support-automation"
            element={<CustomerSupportAutomation />}
          />
          <Route
            path="/solution/information-technology"
            element={<InformationTechnology />}
          />
          <Route path="/solution/procurement" element={<Procurement />} />
          <Route
            path="/solution/other-industries"
            element={<OtherIndustries />}
          />
          <Route
            path="/solution/human-resources"
            element={<HumanResources />}
          />
          <Route path="/ai-avatar-assistant" element={<AIAvatarAssist />} />
          <Route path="/resources" element={<Blogs />} exact={true} />
          <Route path="/resources/blog" element={<Blogs />} />
          <Route path="/resources/casestudy" element={<CaseStudies />} />
          <Route path="/resources/library" element={<Library />} />
          <Route path="/resources/webinars" element={<Webinar />} />
          <Route path="/resources/:id" element={<ResourceDetails />} />
          <Route path="/resources/library/:id" element={<ResourceDetails />} />
          <Route path="/resources/webinars/:id" element={<WebinarDetails />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/career" element={<Career />} />
          <Route path="/career/open-positions" element={<CareerOpenings />} />
          <Route
            path="/career/register-your-interest/new"
            element={<CareerJobInterestForm />}
          />
          <Route path="/contact" element={<Contact />} />

          <Route path="/news" element={<News />} exact={true} />
          <Route path="/news/:id" element={<NewsDetails />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-services" element={<TermsOfService />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="*" element={<NoPageFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;
