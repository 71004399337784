import React from "react";
import { BiSolidUser, BiTimeFive } from "react-icons/bi";
import { FaBlog, FaRegCalendar } from "react-icons/fa";
import { RiBook2Line } from "react-icons/ri";
import moment from "moment";
import { PiVideoFill } from "react-icons/pi";
import { Divider } from "antd";
function ResourceBanner(props) {
  return (
    <>
      <div className="outerContainer resource-banner">
        <div className="innerContainer">
          <div className="resource-banner-title">{props.content.title}</div>
          <div className="resource-details-items-info">
            <div className="resource-details-items-info-type">
              {props.content.type === "Blog" ? (
                <FaBlog size={15} />
              ) : props.content.type === "Case study" ? (
                <RiBook2Line size={15} />
              ) : (
                <PiVideoFill size={15} />
              )}
              {props.content.type}
            </div>
            <Divider type="vertical" />
            <div className="resource-details-items-info-date">
              <FaRegCalendar size={15} />

              {moment.unix(props.content.publishedDate).format("MMMM Do YYYY")}
            </div>
            <Divider type="vertical" />
            {props.content.author && (
              <div className="resource-details-items-info-author">
                <BiSolidUser size={15} />
                {props.content.author}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ResourceBanner;
