import { FaExpandArrowsAlt, FaSyncAlt } from "react-icons/fa";
import { HiBadgeCheck } from "react-icons/hi";
import { IoMdRocket } from "react-icons/io";
export const dataGovernanceContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Our Gen AI solutions are crafted to uphold the highest standards of data quality, security, and privacy. We offer comprehensive frameworks and practices that enhance your data’s integrity and accessibility while ensuring compliance with industry regulations. Our solutions enable effective management of your data lifecycle, empowering you to make informed decisions and protect your business’s most valuable asset. Rely on iNextLabs to oversee your data from collection through usage, ensuring that your AI systems function smoothly and responsibly.",
    imageURL: "/images/products/data-governance/banner.webp",
    sectionTile: "",
    title: "Unlock success with expert data governance",
  },

  stats: {
    title: "Unlock AI success with data governance",
    description: "Ensure quality, cut costs, and drive growth",
    content: [
      {
        id: "1",
        count: 85,
        title:
          "of AI Projects fail due to data quality issues—data governance is essential.",
      },
      {
        id: "2",
        count: 20,
        title: "Reduction in operational costs with effective data governance.",
      },
      {
        id: "3",
        count: 19.86,
        countType: "Bn+",
        title: "Projected global data governance market size by 2032.",
      },
      {
        id: "4",
        count: 20.6,
        title: "CAGR growth in the data governance sector from 2024 to 2032.",
      },
    ],
  },

  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "/images/products/data-governance/enhanced-data-integrity.webp",
      pointer: "/images/icons/pointer.svg",
      title: "Enhanced data integrity and quality",
      mainDescription:
        "Our data governance practices ensure that your data remains accurate, consistent, and reliable. By implementing rigorous data validation and cleansing techniques, we help maintain high data quality, which is crucial for making informed decisions and optimizing AI-driven outcomes.",
    },
    {
      id: "2",
      model: "model1",
      imageURL: "/images/products/data-governance/compliance-and-risk.webp",
      pointer: "/images/icons/pointer.svg",
      title: "Compliance and risk management",
      mainDescription:
        "Navigate the complex landscape of regulatory requirements with ease. Our governance frameworks are designed to help organizations comply with data protection laws and standards. We provide practices that address potential data risks and help you avoid legal pitfalls, ensuring your operations align with industry regulations.",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "/images/products/data-governance/secure-data-management.webp",
      pointer: "/images/icons/pointer.svg",
      title: "Secure data management",
      mainDescription:
        "Protect your sensitive data with our robust governance practices. We focus on establishing clear protocols for data access and management, reducing the risk of unauthorized access and safeguarding your data against potential security breaches and cyber threats.",
    },
    {
      id: "4",
      model: "model1",
      imageURL:
        "/images/products/data-governance/optimized-data-utilization.webp",
      pointer: "/images/icons/pointer.svg",
      title: "Optimized data utilization",
      mainDescription:
        "Make the most of your data through streamlined access and management practices. Our governance approach ensures that data is easily accessible to authorized users and effectively utilized, enhancing the efficiency of your AI solutions and supporting better decision-making processes.",
    },
    {
      id: "5",
      model: "model1",
      imageURL:
        "/images/products/data-governance/transparency-and-accountability.webp",
      pointer: "/images/icons/pointer.svg",
      title: "Transparency and accountability",
      mainDescription:
        "Foster a culture of accountability with clear data governance practices. We provide frameworks that ensure transparency in data processes and ownership, making it easier to track data usage and maintain a clear record of who is responsible for data management and stewardship.",
    },
  ],

  whyChooseUs: {
    title: "Why choose us",
    tagline: "It's more than just these reasons",
    content: [
      {
        id: "1",
        title: "Adapt to your needs",
        tagline: "Adaptable tools designed for your specific business needs.",
        icon: <FaSyncAlt size={20} />,
      },
      {
        id: "2",
        title: "Guidance you can trust",
        tagline: "Continuous support from data governance specialists.",
        icon: <HiBadgeCheck size={22} />,
      },
      {
        id: "3",
        title: "Scalable infrastructure",
        tagline: "Solutions that scale with your business growth.",
        icon: <FaExpandArrowsAlt size={20} />,
      },
      {
        id: "4",
        title: "Innovative technology",
        tagline: "Advanced tools to enhance your data management.",
        icon: <IoMdRocket size={20} />,
      },
    ],
  },

  FAQ: [
    {
      id: "1",
      question: "What is Data Governance, and why is it important?",
      answer:
        "Data Governance is the framework of policies, procedures, and standards that manage data throughout its lifecycle. It’s crucial because it ensures data accuracy, security, and compliance, helping businesses make informed decisions and protecting against data breaches and regulatory penalties.",
    },
    {
      id: "2",
      question: "How does Data Governance differ from Data Management?",
      answer:
        "Data Governance focuses on setting policies and standards for data quality, security, and compliance. Data Management, on the other hand, involves the day-to-day operations of handling and processing data. Governance provides the rules, while management implements them.",
    },
    {
      id: "3",
      question: "What are the key components of a Data Governance framework?",
      answer:
        "A Data Governance framework typically includes data quality management, data security policies, data privacy regulations, access controls, and data stewardship. These components work together to ensure that data is accurate, secure, and used appropriately.",
    },
    {
      id: "4",
      question: "How can Data Governance help with regulatory compliance?",
      answer:
        "Data Governance helps with regulatory compliance by establishing policies and procedures that align with legal requirements, such as GDPR or CCPA. It ensures that data handling practices are transparent, secure, and respectful of privacy laws, reducing the risk of non-compliance.",
    },
  ],

  meta: {
    title: "AI for Data Governance | Ensure Quality and Security",
    description:
      "Use iNextLabs' AI for data governance to enhance data quality and security. Ensure compliance while managing your data lifecycle for informed decisions.",
    locale: "en_US",
    siteName: "iNextLabs",
    image:
      "https://www.inextlabs.com/images/products/data-governance/meta-data-governance.jpg",
    type: "website",
  },
};
