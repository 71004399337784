import React from "react";
import Divider from "../../Divider";

const IntegrationSection = (props) => {
  const integrationContent = props.content.content;
  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="integration-container">
            <div className="integration-section-header">
              <h2 className="sectionTitle">{props.content.title}</h2>
              <p className="para">{props.content.description}</p>
              <Divider />
            </div>
            <div className="integration-card-wrapper">
              {integrationContent.map((integration, index) => (
                <div className="integration-card" key={index}>
                  <div className="integration-card-icon">
                    <img
                      src={integration.imageURL}
                      alt="sygnet"
                      loading="lazy"
                    />
                  </div>
                  <div className="integration-card-content">
                    <h3 className="paragraphTitle">{integration.title}</h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IntegrationSection;
