import React from "react";
import Divider from "../../../Divider";

const AboutCoreValues = (props) => {
  const coreValuesContent = props.content;
  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="about-core-values-container">
            <h2 className="about-core-values-title sectionTitle">
              Our Core Values
            </h2>
            <Divider />
            <div className="about-core-values-list-container">
              {coreValuesContent.map((values, index) => (
                <div className="about-core-values-list-card" key={index}>
                  <div className="about-core-values-list-icon">
                    <img
                      src={values.imageURL}
                      alt="core value icon"
                      width={50}
                      loading="lazy"
                    />
                  </div>
                  <h4 className="about-core-values-list-title">
                    {values.title}
                  </h4>
                  <div className="about-core-values-list-text">
                    <p>{values.descriptionOne}</p>
                    <p>{values.descriptionTwo}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCoreValues;
