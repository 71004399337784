import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Divider from "../Divider";

const Clients = (props) => {
  const images = props.content;

  return (
    <div className="clients-container outerContainer">
      <h2 className="clients-container-title">
        Trusted By Companies Worldwide
      </h2>
      <Divider />
      <ImageSlider images={images} width={200} height={200} />
    </div>
  );
};

export default Clients;
const ImageSlider = ({ images, width, height, reverse }) => {
  return (
    <div
      className={`slider ${reverse ? "reverse" : ""}`}
      style={{
        "--width": `${width}px`,
        "--height": `${height}px`,
      }}
    >
      <div className="list">
        {[...images, ...images].map((src, index) => (
          <div className="item" key={index}>
            <img src={src} alt={`slider_image_${index + 1}`} loading="lazy" />
          </div>
        ))}
      </div>
    </div>
  );
};
