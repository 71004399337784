import {
  MdOutlineSecurity,
  MdOutlineSupportAgent,
  MdScreenshotMonitor,
} from "react-icons/md";
import { GiGrowth } from "react-icons/gi";
export const integrationsContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Unlock the power of iNextLabs AI by seamlessly connecting to the platforms your team already uses. Our integrations ensure a smooth setup that enhances efficiency and speeds up results. Leverage connections with leading systems to double your value and scale effortlessly. We offer comprehensive AI integration services that help you deliver better customer experiences, optimize operations, and drive effective innovation.",
    imageURL: "/images/products/integration/banner.webp",
    sectionTile: "",
    title: "Integrate effortlessly with iNextLabs",
  },

  stats: {
    title: "Seamless integration for rapid results",
    description:
      "Accelerate Efficiency and Maximize ROI with Effortless AI Connectivity",
    content: [
      {
        id: "1",
        count: 30,
        title: "faster implementation with seamless AI integration.",
      },
      {
        id: "2",
        count: 50,
        title:
          "reduction in system compatibility issues with smart integrations.",
      },
      {
        id: "3",
        count: 40,
        title:
          "increase in operational efficiency through connected platforms.",
      },
      {
        id: "4",
        count: 25,
        title: "boost in ROI from integrated AI solutions.",
      },
    ],
  },

  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "/images/products/integration/upgrade-stack.webp",
      pointer: "/images/icons/pointer.svg",
      title: "Upgrade your technology stack",
      mainDescription:
        "Elevate your current technology stack with iNextLabs AI, seamlessly integrating advanced capabilities into your existing systems. Our solutions enhance performance while preserving your current workflows, maximizing the value of your tech investments with minimal disruption.",
    },
    {
      id: "2",
      model: "model1",
      imageURL: "/images/products/integration/tailored-integrations.webp",
      pointer: "/images/icons/pointer.svg",
      title: "Tailored AI integrations",
      mainDescription:
        "Experience personalized AI solutions with custom integrations designed to match your unique needs. iNextLabs ensures smooth connections with your systems, whether they’re legacy or cutting-edge, allowing you to enhance your processes with tailored technology.",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "/images/products/integration/streamlined-knowledge.webp",
      pointer: "/images/icons/pointer.svg",
      title: "Streamlined knowledge management",
      mainDescription:
        "Revolutionize your knowledge management with iNextLabs AI, designed to organize and utilize information more effectively. Our solutions integrate with your systems to provide easy access to critical data, improving decision-making and operational efficiency.",
    },
    {
      id: "4",
      model: "model1",
      imageURL: "/images/products/integration/unified-data-flow.webp",
      pointer: "/images/icons/pointer.svg",
      title: "Unified and accurate data flow",
      mainDescription:
        "Achieve a unified and accurate data flow with iNextLabs’ integration solutions. Our technology ensures real-time synchronization and accuracy across all systems, eliminating data silos and enhancing operational efficiency for better decision-making.",
    },
  ],

  whyChooseUs: {
    title: "Why choose us",
    tagline: "Here's why businesses trust iNextLabs",
    content: [
      {
        id: "1",
        title: "Scalable solutions for growth",
        tagline: "Flexibility to grow with your business.",
        icon: <GiGrowth size={20} />,
      },
      {
        id: "2",
        title: "Proven reliability and security",
        tagline: "Dependable AI with advanced protection.",
        icon: <MdOutlineSecurity size={20} />,
      },
      {
        id: "3",
        title: "User-friendly experience",
        tagline: "Intuitive design for easy adoption.",
        icon: <MdScreenshotMonitor size={20} />,
      },
      {
        id: "4",
        title: "Expert support and innovation",
        tagline: "Continuous improvement with top-tier support.",
        icon: <MdOutlineSupportAgent size={20} />,
      },
    ],
  },

  FAQ: [
    {
      id: "1",
      question:
        "How does iNextLabs AI integration work with my existing systems?",
      answer:
        "iNextLabs AI integrates seamlessly with your current technology stack by connecting to your existing platforms and applications. Our solutions are designed to work with various systems, enhancing their capabilities without requiring a complete overhaul. The integration process is straightforward and aimed at improving your existing workflows with minimal disruption.",
    },
    {
      id: "2",
      question:
        "What kind of support does iNextLabs provide during the integration process?",
      answer:
        "We offer comprehensive support throughout the integration process, including setup, configuration, and troubleshooting. Our team of experts is available to assist with any issues or questions that arise, ensuring a smooth and efficient integration. We also provide detailed documentation and training to help your team get up to speed quickly.",
    },
    {
      id: "3",
      question: "Will integrating iNextLabs AI disrupt my current operations?",
      answer:
        "Our integration solutions are designed to be non-disruptive, aiming to enhance your existing systems without causing interruptions to your daily operations. We carefully plan and execute the integration to minimize any potential impact, ensuring that your business can continue to run smoothly throughout the process.",
    },
    {
      id: "4",
      question: "How long does the integration process take?",
      answer:
        "The duration of the integration process depends on the complexity of your existing systems and the scope of the integration. Generally, our solutions are designed to be implemented quickly and efficiently. We provide a clear timeline and keep you informed throughout the process to ensure that the integration is completed as smoothly and promptly as possible.",
    },
  ],

  meta: {
    title: "AI Integration Services | Seamless Connection with iNextLabs",
    description:
      "Unlock the power of iNextLabs with our AI integration services. Connect effortlessly to existing platforms, enhance efficiency, and improve customer experiences.",
    locale: "en_US",
    siteName: "iNextLabs",
    image:
      "https://www.inextlabs.com/images/products/integration-ai/meta-integration-ai.jpg",
    type: "website",
  },
};
