import React, { useEffect, useState } from "react";
import ContactForm from "../contact/form/ContactForm";
import { Link } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { BiSolidUser } from "react-icons/bi";
import moment from "moment";
import { FaRegCalendar } from "react-icons/fa";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import { BsCalendarEvent } from "react-icons/bs";
import { Divider } from "antd";
import { newsContent } from "./newsContent";

const NewsDetailsItems = (props) => {
  const [copied, setCopied] = useState(false);
  const currentUrl = window.location.href;
  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 30000); // 60 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or copied state change
    }
  }, [copied]);

  const relatedNews = newsContent.newsItems.filter(
    (news) => news.type === props.content.type && news.url !== props.content.url
  );
  const relatedNewsContent = relatedNews.slice(0, 3);
  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="resource-details-items-container">
            <div className="resource-details-items-content-container">
              <div className="resource-details-items-content-image">
                <img
                  src={props.content.imageURL}
                  alt="news cover image"
                  loading="lazy"
                />
              </div>
              <div className="resource-details-items-content-data-container">
                <div className="resource-details-items-content">
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.content.content,
                      }}
                      className="resource-details-items-content-data"
                    ></div>
                    {props.content.registerUrl && (
                      <div className="resource-details-items-content-button button">
                        <Link to={props.content.registerUrl} target="blank">
                          <button className="button">Register Now</button>
                        </Link>
                      </div>
                    )}
                    <Divider />

                    <div className="resource-details-items-content-social-share">
                      Share this article:
                      <FacebookShareButton
                        url={currentUrl}
                        quote={props.content.title}
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={currentUrl}
                        title={props.content.title}
                      >
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        url={currentUrl}
                        title={props.content.title}
                      >
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                      <WhatsappShareButton
                        url={currentUrl}
                        title={props.content.title}
                        separator=":: "
                      >
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                    </div>
                    <div className="copy-link-container">
                      <input value={currentUrl} />
                      <CopyToClipboard
                        text={currentUrl}
                        onCopy={() => setCopied(true)}
                      >
                        <Button type="primary">
                          {!copied ? "Copy" : "Copied"}
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
                <div className="resource-details-items-related-content">
                  <div className="resource-details-items-more-title sectionTitle left">
                    Related Resources
                  </div>
                  <div className="resource-details-items-more-list">
                    {relatedNewsContent.map((resource, index) => (
                      <div
                        key={index}
                        className="resource-details-items-more-list-item"
                      >
                        <a href={resource.url}>
                          <img
                            src={resource.imageURL}
                            alt="resources image"
                            className="resource-details-items-more-list-item-image"
                            loading="lazy"
                          />
                          <div className="resource-details-items-more-list-item-title">
                            {resource.title}
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsDetailsItems;
